<header class="navbar navbar-expand-lg navbar-dark fixed-top nga-navbar">
  <nav class="container" aria-label="Main navigation">
    <a routerLink="/" class="navbar-brand" alt="Accueil">
      <img
        src="./assets/images/logo-devops-web.png"
        srcset="./assets/images/logo-devops-web.png"
        alt="Ganatan Logo"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mx-right">
        <li class="nav-item">
          <a
            class="nav-link nga-nav-link"
            aria-current="page"
            routerLink="/formations"
          >
            Formations</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link nga-nav-link"
            aria-current="page"
            routerLink="/prestations"
          >
            Prestations</a
          >
        </li>

        <li class="nav-item">
          <a
            class="nav-link nga-nav-link"
            aria-current="page"
            href="https://github.com/devops-web-fr"
          >
            <i class="fab fa-github me-1"></i>Github
          </a>
        </li>
        <!-- <li class="nav-item">
          <a
            class="nav-link nga-nav-link"
            aria-current="page"
            href="formations"
          >
            <i class="fab fa-github me-1"></i>Portail Formation
          </a>
        </li> -->

        <li class="nav-item">
          @if (currentUser && isLoggedIn) {
          <a
            class="nav-link nga-nav-link"
            aria-current="page"
            routerLink="auth"
          >
            <i class="fas fa-user me-2"></i>
            {{ currentUser.firstName }}
          </a>

          } @else{
          <a
            class="nav-link nga-nav-link"
            aria-current="page"
            routerLink="auth/login"
          >
            <i class="fas fa-user me-2"></i>
            Mon compte
          </a>
          }
        </li>
        <li class="nav-item">
          @if (currentUser && isLoggedIn) {
          <a class="nav-link nga-nav-link" aria-current="page">
            <button
              type="button"
              class="btn btn-sm btn-outline-light me-2"
              (click)="logout()"
            >
              <i class="fas fa-sign-out-alt me-1"></i>Se Deconnecter
            </button>
          </a>

          }
        </li>
        <li class="nav-item">
          <a
            class="nav-link nga-nav-link-contact contact"
            aria-current="page"
            routerLink="/contact"
          >
            <i class="fas fa-envelope me-1"></i>Nous contacter
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>
